import {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import VanityLayout from "../layouts/vanity";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {pathname} = useLocation();

    try {
        return (
            <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
                <Component {...props} />
            </Suspense>
        );
    } catch (e) {
        console.error('Loadable: error', e)
    }
};

export default function Router() {
    return useRoutes([
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                {path: 'coming-soon', element: <ComingSoon/>},
                {path: 'maintenance', element: <Maintenance/>},
                {path: '500', element: <Page500/>},
                {path: '404', element: <NotFound/>},
                {path: '401', element: <NoAccess/>},
                {path: '*', element: <Navigate to="/404" replace/>},
            ],
        },
        {
            path: '/',
            element: <MainLayout/>,
            children: [
                {element: <HomePage/>, index: true},
                {path: 'payment/:pricingTier/:billingPlan', element: <Payment/>},
                {path: 'profiles', element: <Profiles/>},
                {path: 'profiles/:actor', element: <Profiles/>},
                {path: 'profiles/:actor/:agent', element: <Profiles/>},
                {path: 'pricing', element: <Pricing/>},
                {path: 'releases', element: <Releases/>},
                {path: 'blogs', element: <BlogPosts/>},
                {path: 'blog/post/:id', element: <BlogPost/>},
                {path: 'about-us', element: <About/>},
                {path: 'vehicle-training', element: <VehicleTraining/>},
                {path: 'privacy-policy', element: <PrivacyPolicy/>},
                {path: 'terms-and-conditions', element: <TermsAndConditions/>},
                {path: 'sla', element: <ServiceLevelAgreement/>},
                {path: 'contact-us', element: <Contact/>},
                {path: 'faqs', element: <Faqs/>},
            ],
        },
        {
            path: '/provider',
            element: <VanityLayout/>,
            children: [
                {path: ':company', element: <VanityPage/>},
            ],
        },
        {path: '*', element: <Navigate to="/404" replace/>},
    ]);
}

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/BlogPost')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const VehicleTraining = Loadable(lazy(() => import('../pages/VehicleTraining')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
const ServiceLevelAgreement = Loadable(lazy(() => import('../pages/ServiceLevelAgreement')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Releases = Loadable(lazy(() => import('../pages/Releases')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Profiles = Loadable(lazy(() => import('../pages/Profiles')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NoAccess = Loadable(lazy(() => import('../pages/Page401')));

// VANITY
const VanityPage = Loadable(lazy(() => import('../pages/vanity/Home')));
