import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import numeral from 'numeral';
// '@mui
import {CURRENCIES, LANGS} from '../config'

// ----------------------------------------------------------------------

export default function useLocales() {
    const {i18n, t: translate} = useTranslation();
    const langStorage = localStorage.getItem('i18nextLng');
    const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];
    const [currentCurrencySymbol, setCurrencySymbol] = useState('');

    useEffect(() => {
        numeral.locale(currentLang.value);
        setCurrencySymbol(CURRENCIES[currentLang.currencyCode].symbol.default.display)
    }, [currentLang])

    const handleChangeLanguage = (newlang) => {
        i18n.changeLanguage(newlang);
    };

    return {
        onChangeLang: handleChangeLanguage,
        currentCurrencySymbol,
        translate,
        currentLang,
        allLang: LANGS,
    };
}
