import { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import jwtDecode from 'jwt-decode';
import useAuth from '../hooks/useAuth';
import uuidv4 from '../utils/uuidv4';
import { dispatch, useSelector } from '../redux/store';
import { clearSession, setSession, setTimeLeft } from '../redux/slices/session';

const initialSession = {
  initialized: false,
  loading: false,
  role: null,
  Session: null,

};

const SessionContext = createContext({
  Session: initialSession,
  hasAccess: () => false,
}); // component props type

const SessionProvider = ({ children }) => {

  const {
    timeLeft,
    checkEveryXMinutes,
    showSessionWarning,
    accessToken,
  } = useSelector((state) => state.session);

  const [id] = useState(uuidv4());

  const { logout, isAuthenticated, loginWithRedirect } = useAuth();
  const navigate = useNavigate();

  const checkTimeLeft = useCallback(() => {
    try {
      if (accessToken) {
        const claims = jwtDecode(accessToken);
        const now = Math.floor(new Date().getTime() / 1000);
        const secondsLeft = claims.exp - now;
        return secondsLeft;
      }
      return 60 * 6000;
    } catch (e) {
      console.error(e);
    }
    return 60 * 6000;
  }, [accessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setSession(window.localStorage.getItem('accessToken') || null));
    } else {
      dispatch(clearSession());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (timeLeft <= 0 && logout && navigate) {
      logout()
        .then(() => {
          navigate('/', { replace: true });
        });
    }
  }, [timeLeft, logout, navigate]);

  useEffect(() => {
    if (typeof checkTimeLeft === 'function' && checkEveryXMinutes && accessToken && !showSessionWarning) {
      setTimeout(() => {
        try {
          dispatch(setTimeLeft(checkTimeLeft()));
        } catch (e) {
          console.error(e);
        }
      }, checkEveryXMinutes);
    }
  }, [timeLeft, checkTimeLeft, checkEveryXMinutes, logout, navigate, accessToken, id, showSessionWarning]);

  const resetSession = async () => {
    try {
      await loginWithRedirect();
      // dispatch(setSession(window.localStorage.getItem('accessToken') || null));
    } catch (error) {
      console.error('resetSession: Error', error);
    }
  };

  const closeSession = async () => {
    await logout();
    dispatch(clearSession());
  };

  return (
    <SessionContext.Provider
      value={{}}
    >
      <Dialog open={showSessionWarning}>
        <DialogTitle>Session Expiring</DialogTitle>
        <DialogContent>Your session is going to expire soon. Would you like to continue working?</DialogContent>
        <DialogActions>
          <Button onClick={resetSession}>Yes, I want more time</Button>
          <Button onClick={closeSession}>Nope, I'm good</Button>
        </DialogActions>
      </Dialog>
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node,
};


export { SessionProvider, SessionContext };

