import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
import axios from "../../utils/axios";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    alertsCollected: false,
    unprocessedAlerts: [],
    alerts: {byId: {}, allIds: []}
};

function objFromArray(array, key = '_id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}


const slice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.alertsCollected = false;
        },

        // GET ALERTS
        getAlertsSuccess(state, action) {
            state.isLoading = false;
            state.alerts.byId = objFromArray(action.payload);
            state.alerts.allIds = Object.keys(state.alerts.byId);
            state.alertsCollected = true;
        },

        // ADD ALERT
        addAlertSuccess(state, action) {
            const alert = action.payload[0];
            state.alerts.byId[alert._id] = alert;
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // RECEIVE ALERT
        alertReceivedSuccess(state, action) {
            const alert = action.payload;
            state.unprocessedAlerts.push(alert)
        },

        // PROCESS ALERT
        alertProcessedSuccess(state, action) {
            const unprocessedAlerts = action.payload;
            state.unprocessedAlerts = unprocessedAlerts;
        },

        // ADD ALERT
        removeAlertSuccess(state, action) {
            const alertId = action.payload;
            if (state.alerts.byId[alertId]) {
                delete state.alerts.byId[alertId]
            }
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // ADD ALERT
        markAlertAsRead(state, action) {
            const alerts = action.payload;
            alerts.forEach((alert) => {
                state.alerts.byId[alert._id] = alert;
            })
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // ADD ALERT
        updateAlertSuccess(state, action) {
            const alerts = action.payload;
            alerts.forEach((alert) => {
                state.alerts.byId[alert._id] = alert;
            })
            state.alerts.allIds = Object.keys(state.alerts.byId);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addAlert(alert) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/api/alert`, {
                alert
            });
            dispatch(slice.actions.addAlertSuccess(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateAlert(alert) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/alert`, {
                alert
            });
            dispatch(slice.actions.updateAlertSuccess(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAlerts(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/alert`, {
                params: {userId},
            });
            dispatch(slice.actions.getAlertsSuccess(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markAlertAsRead(alertId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/alert`, {
                alert: {_id: alertId, isUnRead: false}
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeAlert(alertId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/api/alert`, {
                params: {alert: alertId}
            });
            dispatch(slice.actions.removeAlertSuccess(alertId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeAllAlerts(alertIds) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/api/alert`, {
                params: {alert: alertIds.join(',')}
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markAllAlertAsRead(alertIds) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/api/alert`, {
                alerts: alertIds.map((alertId) => ({_id: alertId, isUnRead: false}))
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alert));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function alertReceived(alert) {
    return async () => {
        dispatch(slice.actions.alertReceivedSuccess(alert))
    }
}


// ----------------------------------------------------------------------

export function processAlert(unprocessedAlerts) {
    return async () => {
        dispatch(slice.actions.alertProcessedSuccess(unprocessedAlerts))
    }
}
