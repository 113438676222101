import jwtDecode from 'jwt-decode';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    // ----------------------------------------------------------------------

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

const setSession = (accessToken, idToken) => {
    if (accessToken && idToken) {
        window.localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common['x-dolooma-authentication'] = accessToken;
        axios.defaults.headers.common['x-dolooma-authorization'] = idToken;

    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common['x-dolooma-authentication'];
        delete axios.defaults.headers.common['x-dolooma-authorization'];
    }
};

const setOrganizationSession = (organization) => {
    if (organization) {
        window.localStorage.setItem('activeOrganization', organization);
        axios.defaults.headers.common['x-dolooma-organization'] = organization;
    } else {
        localStorage.removeItem('activeOrganization');
        delete axios.defaults.headers.common['x-dolooma-organization'];
    }
};

const setWorkspaceSession = (workspace) => {
    if (workspace) {
        window.localStorage.setItem('activeWorkspace', workspace);
        axios.defaults.headers.common['x-dolooma-workspace'] = workspace;
    } else {
        // localStorage.removeItem('activeWorkspace');
        delete axios.defaults.headers.common['x-dolooma-workspace'];
    }
};

export {isValidToken, setSession, setOrganizationSession, setWorkspaceSession};
