import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  sessionCollected: false,
  error: null,
  accessToken: null,
  timeLeft: 600,
  secondsToWarn: 600,
  checkEveryXMinutes: 5000, // 60 * 1000, // 1 minute => 60000 miliseconds
  showSessionWarning: false,
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    // START LOADING
    startLoadingSession(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WORKSPACES
    getSessionSuccess(state, action) {
      state.isLoading = false;
      state.sessionCollected = true;
      state.accessToken = action.payload;
    },

    // GET WORKSPACES
    resetSessionSuccess(state) {
      state.isLoading = initialState.isLoading;
      state.sessionCollected = initialState.sessionCollected;
      state.error = initialState.error;
      state.accessToken = initialState.accessToken;
      state.timeLeft = initialState.timeLeft;
      state.secondsToWarn = initialState.secondsToWarn;
      state.checkEveryXMinutes = initialState.checkEveryXMinutes;
      state.showSessionWarning = initialState.showSessionWarning;
    },

    getTimeLeftSuccess(state, action) {
      state.isLoading = false;
      state.timeLeft = action.payload;
      state.showSessionWarning = state.timeLeft <= state.secondsToWarn;
    },
  },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setTimeLeft(timeLeft) {

  return async () => {
    dispatch(slice.actions.startLoadingSession());
    try {
      dispatch(slice.actions.getTimeLeftSuccess(timeLeft));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setSession(accessToken) {

  return async () => {
    dispatch(slice.actions.startLoadingSession());
    try {
      dispatch(slice.actions.getSessionSuccess(accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearSession() {

  return async () => {
    dispatch(slice.actions.startLoadingSession());
    try {
      dispatch(slice.actions.resetSessionSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSession(organization, activeId) {
  return async () => {
    dispatch(slice.actions.startLoadingSession());
    try {
      const response = await axios.get('/api/session', {
        params: { organization },
      });
      const { session } = response.data;
      dispatch(slice.actions.getSessionSuccess({ session, activeId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

