import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Container, Divider, Grid, Link, Stack, Typography} from '@mui/material';
// routes
import {PATH_PAGE} from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import useLocales from "../../hooks/useLocales";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: 'Dolooma',
        children: [
            {name: 'About us', href: PATH_PAGE.about},
            {
                name: 'Contact us',
                external: true,
                href: 'https://outlook.office365.com/owa/calendar/Sales@dolooma.com/bookings/'
            },
            // {name: 'FAQs', href: PATH_PAGE.faqs},
            {
                name: 'Blog',
                icon: <Iconify icon={'carbon:blog'} {...ICON_SIZE} />,
                href: PATH_PAGE.blog
            },
            {
                name: 'FAQs',
                icon: <Iconify icon={'eva:question-mark-circle-outline'} {...ICON_SIZE} />,
                href: PATH_PAGE.faqs
            },
        ],
    },
    {
        headline: 'Legal',
        children: [
            {name: 'Terms and Condition', href: PATH_PAGE.termsAndConditions},
            {name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy},
            {name: 'Service Level Agreement', href: PATH_PAGE.sla},
        ],
    },
    {
        headline: 'Contact',
        children: [
            {
                name: 'support@dolooma.com',
                external: true,
                href: 'mailto:support@dolooma.com?subject=[Feedback] from Customer'
            },
            {
                name: '1.838.202.6857',
                external: true,
                href: 'tel:1-844-427-8222'
            },
            {
                name: 'Seaside, OR 97138',
                target: '_blank',
                external: true,
                href: 'https://www.google.com/maps/place/Seaside,+OR+97138/data=!4m2!3m1!1s0x5493612cb60e6667:0x8d640cf4541bfd22?sa=X&ved=2ahUKEwjppOjTkoD5AhXnQzABHYI6DrcQ8gF6BAh1EAE'
            },
        ],
    },
];

const RootStyle = styled('div')(({theme}) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {

    const {translate} = useLocales();

    return (
        <RootStyle>
            <Divider/>
            <Container sx={{pt: 10}}>
                <Grid
                    container
                    justifyContent={{xs: 'center', md: 'space-between'}}
                    sx={{textAlign: {xs: 'center', md: 'left'}}}
                >
                    <Grid item xs={12} sx={{mb: 3}}>
                        <Logo large sx={{width: 225, height: 100, mx: {xs: 'auto', md: 'inherit'}}}/>
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <Typography variant="body2" sx={{pr: {md: 5}}}>
                            {translate('navigation.mainFooter.description')}
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent={{xs: 'center', md: 'flex-start'}}
                            sx={{mt: 5, mb: {xs: 5, md: 0}}}
                        >
                            <SocialsButton sx={{mx: 0.5}}/>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Stack
                            spacing={5}
                            direction={{xs: 'column', md: 'row'}}
                            justifyContent="space-between"
                        >
                            {LINKS.map((list) => (
                                <Stack key={list.headline} spacing={2}>
                                    <Typography component="p" variant="overline">
                                        {translate(`navigation.mainFooter.${list.headline}`)}
                                    </Typography>
                                    {list.children.map((link) => {
                                        if (link.external) {
                                            return (
                                                <Link
                                                    to={'#'}
                                                    key={link.name}
                                                    color="inherit"
                                                    variant="body2"
                                                    target={link.target}
                                                    sx={{display: 'block'}}
                                                    component={RouterLink}
                                                    onClick={() => {
                                                        window.open(link.href, link.target)
                                                    }}
                                                >
                                                    {translate(`navigation.mainFooter.${link.name}`)}
                                                </Link>
                                            )
                                        }

                                        return (
                                            <Link
                                                to={link.href}
                                                key={link.name}
                                                color="inherit"
                                                variant="body2"
                                                target={link.target}
                                                component={RouterLink}
                                                sx={{display: 'block'}}
                                            >
                                                {translate(`navigation.mainFooter.${link.name}`)}
                                            </Link>
                                        )
                                    })}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: {xs: 'center', md: 'left'},
                    }}
                >
                    © 2021. {translate('navigation.mainFooter.allRightsReserved')}
                    <br/> made by &nbsp;
                    <Link href="https://aeitek.org/">AEITek</Link>
                </Typography>
            </Container>
        </RootStyle>
    );
}
