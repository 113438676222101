import jwtDecode from "jwt-decode";
import useAuth from "./useAuth";

const useSessionManager = () => {
    const auth = useAuth()
    const secondsToWarn = 600; // 10 Minutes

    const checkAuthToken = () => {
        const secondsLeft = checkTimeLeft();

        if (secondsLeft <= 0) {
            // Session over
            auth.logout();
            return false;
        }

        if (secondsLeft <= secondsToWarn) {
            // session close to ending
            return true;
        }

        return false;
    }

    const checkTimeLeft = () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const claims = jwtDecode(accessToken);
        const now = Math.floor(new Date().getTime() / 1000);
        const secondsLeft = claims.exp - now;

        return secondsLeft;
    }

    return {checkTimeLeft, checkAuthToken}
};

useSessionManager.propTypes = {}

export default useSessionManager;
