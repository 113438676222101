import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    services: [],
    serviceModifiers: [],
    serviceVehicleSizes: [],
    servicesForConsumer: [],
    serviceQuestions: [],
    serviceSymptoms: [],
    serviceProblemCodes: [],
    service: null
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getServicesSuccess(state, action) {
            state.isLoading = false;
            state.services = action.payload;
        },

        // GET SERVICE QUESTIONS
        getServiceQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.serviceQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceSymptomsSuccess(state, action) {
            state.isLoading = false;
            state.serviceSymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceModifiersSuccess(state, action) {
            state.isLoading = false;
            state.serviceModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getServiceVehicleSizesSuccess(state, action) {
            state.isLoading = false;
            state.serviceVehicleSizes = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getServiceProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.serviceProblemCodes = action.payload;
        },

        // GET SERVICE
        getServiceSuccess(state, action) {
            state.isLoading = false;
            state.service = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getServices(forConsumer, callback) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services', {params: {forConsumer}});
            dispatch(slice.actions.getServicesSuccess(response.data.services));
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/service/questions');
            dispatch(slice.actions.getServiceQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/service/problem/codes');
            dispatch(slice.actions.getServiceProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/service/symptoms');
            dispatch(slice.actions.getServiceSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/service/modifiers');
            console.log('getServiceModifiers', {response})
            dispatch(slice.actions.getServiceModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceVehicleSizes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/service/vehicle/sizes');
            console.log('getServiceVehicleSizes', {response})
            dispatch(slice.actions.getServiceVehicleSizesSuccess(response.data.vehicleSizes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getService(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/service`, {
                params: {identifier},
            });
            dispatch(slice.actions.getServiceSuccess(response?.data?.service ? response.data.service[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setService(service) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
