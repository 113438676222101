import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import alexReducer from './slices/alex';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import partsReducer from './slices/part';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import requestReducer from './slices/request';
import ratesReducer from './slices/rate';
import costsReducer from './slices/cost';
import rateSetupWizardReducer from './slices/rateSetupWizard';
import claimsReducer from './slices/claims';
import companyReducer from './slices/company';
import labelReducer from './slices/label';
import rolesReducer from './slices/role';
import packagesReducer from './slices/package';
import servicesReducer from './slices/service';
import vehiclesReducer from './slices/vehicle';
import hooReducer from './slices/hoo';
import appAssetsReducer from './slices/appAssets';
import locationReducer from './slices/location';
import signalRReducer from './slices/signalR';
import alertsReducer from './slices/alerts';
import organizationReducer from './slices/organization';
import workspacesReducer from './slices/workspaces';
import sessionReducer from './slices/session';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
    alex: alexReducer,
    mail: mailReducer,
    parts: partsReducer,
    labels: labelReducer,
    rates: ratesReducer,
    costs: costsReducer,
    workspaces: workspacesReducer,
    organization: organizationReducer,
    session: sessionReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    companies: companyReducer,
    kanban: kanbanReducer,
    request: requestReducer,
    claims: claimsReducer,
    roles: rolesReducer,
    alerts: alertsReducer,
    hoos: hooReducer,
    locations: locationReducer,
    rateSetupWizard: rateSetupWizardReducer,
    signalR: signalRReducer,
    services: servicesReducer,
    vehicles: vehiclesReducer,
    appAssets: appAssetsReducer,
    packages: packagesReducer,
    product: persistReducer(productPersistConfig, productReducer),
});

export {rootPersistConfig, rootReducer};
