import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    costs: [],
};

const slice = createSlice({
    name: 'costs',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getCostsSuccess(state, action) {
            state.isLoading = false;
            state.costs = action.payload;
        },


    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCosts() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/costs');
            dispatch(slice.actions.getCostsSuccess(response.data.costs));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}
