import axios from 'axios';
import { setSession } from './jwt';
// config
import { HOST_API, HOST_API_KEY } from '../config';

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let CURRENT_REQUESTS = 0;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  params: {
    code: HOST_API_KEY,
  },
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Suite-Identifier': process.env.REACT_APP_SUITE_IDENTIFIER,
    'Access-Control-Allow-Origin': '*',
  },
});


axiosInstance.interceptors.request.use((config) =>
    // Do something before request is sent

    new Promise((resolve) => {
      if (CURRENT_REQUESTS >= MAX_REQUESTS_COUNT) {
        const interval = setInterval(() => {
          if (CURRENT_REQUESTS < MAX_REQUESTS_COUNT) {
            CURRENT_REQUESTS += 1;
            clearInterval(interval);
            resolve({ ...config, headers: { ...config.headers } });
          }
        }, INTERVAL_MS);
      } else {
        CURRENT_REQUESTS += 1;
        resolve({ ...config, headers: { ...config.headers } });
      }
    }), (error) => {
    // Do something with request error
    console.error({ error, toJSON: error.toJSON() });
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    CURRENT_REQUESTS -= 1;
    const { accessToken } = response.data;
    if (accessToken) {
      setSession(accessToken);
    }

    return response;
  },
  (error) => {
    CURRENT_REQUESTS -= 1;
    if (error?.response?.status === 401 && error?.response?.data?.message === 'User is not authenticated.') {
      window.location.reload();
    }
    console.error({ error, toJSON: error.toJSON() });
    return Promise.reject((error.response && error.response?.data) || 'Something went wrong');
  },
);

export default axiosInstance;
