// routes
import {useLocation} from "react-router-dom";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import {ChartStyle} from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import {ProgressBarStyle} from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import {SessionProvider} from "./contexts/SessionContext";

// ----------------------------------------------------------------------

export default function App() {
    const {pathname} = useLocation();

    return (
        <ThemeProvider>
            <ThemeColorPresets>
                <ThemeLocalization>
                    <SessionProvider>
                        <RtlLayout>
                            <NotistackProvider>
                                <MotionLazyContainer>
                                    <ProgressBarStyle/>
                                    <ChartStyle/>
                                    <Settings isDashboard={pathname.includes('/dashboard')}/>
                                    <ScrollToTop/>
                                    <Router/>
                                </MotionLazyContainer>
                            </NotistackProvider>
                        </RtlLayout>
                    </SessionProvider>
                </ThemeLocalization>
            </ThemeColorPresets>
        </ThemeProvider>
    );
}
