import pages from './pages'
import demo from './demo.json'
import navigation from './navigation.json'
import termsAndConditions from './termsAndConditions.json'
import privacyPolicy from './privacyPolicy.json'
import termsOfService from './termsOfService.json'
import serviceLevelAgreement from "./serviceLevelAgreement.json";

const translation = {pages, ...demo, ...navigation, ...serviceLevelAgreement, ...termsAndConditions, ...privacyPolicy, ...termsOfService}
export default translation
