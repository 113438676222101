import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Stack, Typography} from '@mui/material';
import {useTheme} from "@mui/material/styles";

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
    company: PropTypes.string,
    text: PropTypes.string,
    large: PropTypes.bool,
};

export default function Logo({text = '', disabledLink = false, company = '', sx, large}) {

    let localSX = {width: 40, height: 40, ...sx};

    const theme = useTheme();

    let vanitySX = {width: 75, ...sx};

    if (large) {
        localSX = {width: 350, ...sx}
    }
    const logo = (
        <Stack>
            <Box sx={company ? vanitySX : localSX}>
                {company ? <img src={`/logo/${company}.svg`} alt={'logo'}/> :
                    <img src={large ? '/logo/dolooma-logo.png' : '/logo/dolooma-logo2.png'} alt={'logo'}/>}
            </Box>
            {text &&
            <Typography mt={-1} color={theme.palette.text.primary} fontSize={10} variant={"overline"}
                        style={{textDecoration: 'none'}}>{text}</Typography>}
        </Stack>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
