import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    hoos: [],
    hoo: null
};

const slice = createSlice({
    name: 'hoo',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getHoosSuccess(state, action) {
            state.isLoading = false;
            state.hoos = action.payload;
        },

        // GET ROLE
        getHooSuccess(state, action) {
            state.isLoading = false;
            state.hoo = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getHoos(columns) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/hoo', {params: {columns}});
            dispatch(slice.actions.getHoosSuccess(response.data.hoo));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getHoo(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/hoo`, {
                params: {identifier},
            });
            dispatch(slice.actions.getHooSuccess(response?.data?.hoo ? response.data.hoo[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setHoo(hoo) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getHooSuccess(hoo));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
