// routes
// components
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

// const menuConfig = [
//     {
//         title: 'Home',
//         icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
//         path: '/',
//     },
//     {
//         title: 'Who we Support',
//         icon: <Iconify icon={'fa6-solid:people-line'} {...ICON_SIZE} />,
//         path: PATH_PAGE.profiles
//     },
//     {
//         title: 'Products',
//         hidden: true,
//         icon: <Iconify icon={'icon-park-outline:ad-product'} {...ICON_SIZE} />,
//         path: PATH_PAGE.products,
//     },
//     {
//         title: 'Pricing',
//         hidden: true,
//         icon: <Iconify icon={'entypo:price-tag'} {...ICON_SIZE} />,
//         path: PATH_PAGE.pricing,
//     },
//     {
//         title: 'About Us',
//         icon: <Iconify icon={'clarity:info-circle-line'} {...ICON_SIZE} />,
//         path: PATH_PAGE.about
//     },
//     {
//         title: 'Blog',
//         icon: <Iconify icon={'carbon:blog'} {...ICON_SIZE} />,
//         path: PATH_PAGE.blog
//     },
//     {
//         title: 'FAQs',
//         icon: <Iconify icon={'eva:question-mark-circle-outline'} {...ICON_SIZE} />,
//         path: PATH_PAGE.faqs
//     }
// ];

const chaChaMenuConfig = [];

const getMenu = (company) => {
    let companyMenu = []
    switch (company) {
        case "chacha1":
            companyMenu = chaChaMenuConfig;
            break;
        default:
            break;
    }

    console.log({companyMenu})
    return process.env.REACT_APP_ENV === "prod" ? companyMenu : companyMenu.concat([
        {
            title: 'Login',
            icon: <Iconify icon={'mdi:login-variant'} {...ICON_SIZE} />,
            action: 'login',
        }
    ]);
}

export default getMenu
