import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    locations: [],
    location: null,
    caStateCoverage: null,
    caStateCoverageLoaded: false,
    usStateCoverage: null,
    usStateCoverageLoaded: false,
    caStateRegions: null,
    caStateRegionsLoaded: false,
    usStateRegions: null,
    usStateRegionsLoaded: false
};

const slice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getLocationsSuccess(state, action) {
            state.isLoading = false;
            state.locations = action.payload;
        },

        // GET ROLE
        getLocationSuccess(state, action) {
            state.isLoading = false;
            state.location = action.payload;
        },

        // GET ROLE
        getBoundarySuccess(state, action) {
            const {country, location} = action.payload;
            state.isLoading = false;
            state[`${country}StateCoverageLoaded`] = true;
            state[`${country}StateCoverage`] = location;
        },

        // GET ROLE
        getRegionSuccess(state, action) {
            const {country, location} = action.payload;
            state.isLoading = false;
            state[`${country}StateRegionsLoaded`] = true;
            state[`${country}StateRegions`] = location;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLocations(columns) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/locations', {params: {columns}});
            dispatch(slice.actions.getLocationsSuccess(response.data.locations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLocation(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/locations`, {
                params: {identifier},
            });
            dispatch(slice.actions.getLocationSuccess(response?.data?.locations ? response.data.locations[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getBoundaries(country) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/location/boundaries/${country}`);
            const location = response?.data?.location ? response.data.location : null
            dispatch(slice.actions.getBoundarySuccess({location, country}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getRegions(country) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/location/regions/${country}`);
            const location = response?.data?.location ? response.data.location : null
            dispatch(slice.actions.getRegionSuccess({location, country}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setLocation(location) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getLocationSuccess(location));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
