// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REGISTRATION = '/registration';
const ROOTS_CUSTOMER = '/customer';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    // login: path(ROOTS_AUTH, '/login'),
    login: path(ROOTS_AUTH, '/bob'),
    // register: path(ROOTS_AUTH, '/register'),
    register: path(ROOTS_AUTH, '/billy'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    blog: '/blogs',
    blogPost: (id) => `/blog/post/${id}`,
    maintenance: '/maintenance',
    products: '/products',
    pricing: '/pricing',
    profiles: '/profiles',
    actorProfiles: (type) => `/profiles/${type}`,
    payment: '/payment',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    sla: '/sla',
    about: '/about-us',
    vehicleTraining: '/vehicle-training',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_CUSTOMER = {
    root: ROOTS_CUSTOMER,
    general: {
        app: path(ROOTS_CUSTOMER, '/app'),
        ecommerce: path(ROOTS_CUSTOMER, '/ecommerce'),
        general: path(ROOTS_CUSTOMER, '/general'),
        analytics: path(ROOTS_CUSTOMER, '/analytics'),
        banking: path(ROOTS_CUSTOMER, '/banking'),
        booking: path(ROOTS_CUSTOMER, '/booking'),
    },
    coverage: {
        root: path(ROOTS_CUSTOMER, '/coverage'),
        view: path(ROOTS_CUSTOMER, '/coverage/details'),
    },
    document: {
        root: path(ROOTS_CUSTOMER, '/document'),
        list: path(ROOTS_CUSTOMER, '/document/list'),
    },
    location: {
        root: path(ROOTS_CUSTOMER, '/location'),
        list: path(ROOTS_CUSTOMER, '/location/list'),
        new: path(ROOTS_CUSTOMER, '/location/new'),
        edit: (id) => path(ROOTS_CUSTOMER, `/location/${id}/edit`),
        view: (id) => path(ROOTS_CUSTOMER, `/location/${id}`),
    },
    provider: {
        profile: path(ROOTS_CUSTOMER, `/network/provider/profile`),
        profileBid: path(ROOTS_CUSTOMER, `/network/provider/profile`),
    },
    network: {
        root: path(ROOTS_CUSTOMER, '/network'),
        list: path(ROOTS_CUSTOMER, '/network/list'),
        new: path(ROOTS_CUSTOMER, '/network/new'),
        edit: (id) => path(ROOTS_CUSTOMER, `/network/${id}/edit`),
        view: (id) => path(ROOTS_CUSTOMER, `/network/${id}`),
    },
    networkBid: {
        root: path(ROOTS_CUSTOMER, '/network/bid'),
        list: path(ROOTS_CUSTOMER, '/network/bid/list'),
        view: (id) => path(ROOTS_CUSTOMER, `/network/bid/${id}`),
    },
    chat: {
        root: path(ROOTS_CUSTOMER, '/chat'),
        new: path(ROOTS_CUSTOMER, '/chat/new'),
        view: (name) => path(ROOTS_CUSTOMER, `/chat/${name}`),
    },
    customerClaims: {
        root: path(ROOTS_CUSTOMER, '/claim/list'),
        new: path(ROOTS_CUSTOMER, '/claim/new'),
        list: path(ROOTS_CUSTOMER, '/claim/list'),
        view: (id) => path(ROOTS_CUSTOMER, `/claim/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/claim/${id}/edit`)
    },
    jobRequest: {
        root: path(ROOTS_CUSTOMER, '/job/list'),
        new: path(ROOTS_CUSTOMER, '/job/new'),
        list: path(ROOTS_CUSTOMER, '/job/list'),
        view: path(ROOTS_CUSTOMER, `/job/view`),
        edit: path(ROOTS_CUSTOMER, `/job/edit`)
    },
    customerPolicy: {
        root: path(ROOTS_CUSTOMER, '/policy/list'),
        new: path(ROOTS_CUSTOMER, '/policy/new'),
        list: path(ROOTS_CUSTOMER, '/policy/list'),
        view: (id) => path(ROOTS_CUSTOMER, `/policy/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/policy/${id}/edit`)
    },
    invoice: {
        root: path(ROOTS_CUSTOMER, '/invoice'),
        list: path(ROOTS_CUSTOMER, '/invoice/list'),
        new: path(ROOTS_CUSTOMER, '/invoice/new'),
        view: (id) => path(ROOTS_CUSTOMER, `/invoice/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/invoice/${id}/edit`),
    },
    hoo: {
        root: path(ROOTS_CUSTOMER, '/hours-of-operation'),
        list: path(ROOTS_CUSTOMER, '/hours-of-operation/list'),
        new: path(ROOTS_CUSTOMER, '/hours-of-operation/new'),
        view: (id) => path(ROOTS_CUSTOMER, `/hours-of-operation/${id}`),
        edit: (id) => path(ROOTS_CUSTOMER, `/hours-of-operation/${id}/edit`),
    },
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        welcome: path(ROOTS_DASHBOARD, '/welcome'),
        app: path(ROOTS_DASHBOARD, '/general'),
        general: path(ROOTS_DASHBOARD, '/general'),
        ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
        analytics: path(ROOTS_DASHBOARD, '/analytics'),
        banking: path(ROOTS_DASHBOARD, '/banking'),
        booking: path(ROOTS_DASHBOARD, '/booking'),
    },
    organization: {
        root: path(ROOTS_DASHBOARD, '/user/account'),
    },
    rateWizard: {
        root: path(ROOTS_DASHBOARD, '/rate/wizard'),
        rateWizard: path(ROOTS_DASHBOARD, '/rate/wizard'),
    },
    workspace: {
        root: path(ROOTS_DASHBOARD, '/user/account/organization/workspaces'),
    },
    mail: {
        root: path(ROOTS_DASHBOARD, '/mail'),
        all: path(ROOTS_DASHBOARD, '/mail/all'),
    },
    jobRequest: {
        root: path(ROOTS_DASHBOARD, '/job/list'),
        new: path(ROOTS_DASHBOARD, '/job/new'),
        list: path(ROOTS_DASHBOARD, '/job/list'),
        view: path(ROOTS_DASHBOARD, `/job/view`),
        edit: path(ROOTS_DASHBOARD, `/job/edit`)
    },
    chat: {
        root: path(ROOTS_DASHBOARD, '/chat'),
        new: path(ROOTS_DASHBOARD, '/chat/new'),
        create: path(ROOTS_DASHBOARD, `/chat/new`),
        view: path(ROOTS_DASHBOARD, `/chat/view`),
    },
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    claims: path(ROOTS_DASHBOARD, '/claims'),
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        new: path(ROOTS_DASHBOARD, '/user/new'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        cards: path(ROOTS_DASHBOARD, '/user/cards'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        securityProfile: path(ROOTS_DASHBOARD, '/user/profile/security'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
        accountWithTab: (index) => path(ROOTS_DASHBOARD, `/user/account/${index}`),
        accountWithTabAndOverride: (index, override) => path(ROOTS_DASHBOARD, `/user/account/${index}/${override}`),
        accountPasswordOverride: path(ROOTS_DASHBOARD, `/user/account/5/reset`),
        edit: path(ROOTS_DASHBOARD, `/user/edit`)
    },
    company: {
        root: path(ROOTS_DASHBOARD, '/company'),
        new: path(ROOTS_DASHBOARD, '/company/new'),
        list: path(ROOTS_DASHBOARD, '/company/list'),
        cards: path(ROOTS_DASHBOARD, '/company/cards'),
        profile: path(ROOTS_DASHBOARD, '/company/profile'),
        account: path(ROOTS_DASHBOARD, '/company/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/company/${id}/edit`)
    },
    crm: {
        root: path(ROOTS_DASHBOARD, '/crm'),
        new: path(ROOTS_DASHBOARD, '/crm/new'),
        list: path(ROOTS_DASHBOARD, '/crm/list'),
        cards: path(ROOTS_DASHBOARD, '/crm/cards'),
        profile: path(ROOTS_DASHBOARD, '/crm/profile'),
        account: path(ROOTS_DASHBOARD, '/crm/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/crm/${id}/edit`),
        register: (id) => path(ROOTS_DASHBOARD, `/crm/${id}/edit/autoRegister`)
    },
    package: {
        root: path(ROOTS_DASHBOARD, '/package'),
        new: path(ROOTS_DASHBOARD, '/package/new'),
        list: path(ROOTS_DASHBOARD, '/package/list'),
        cards: path(ROOTS_DASHBOARD, '/package/cards'),
        profile: path(ROOTS_DASHBOARD, '/package/profile'),
        account: path(ROOTS_DASHBOARD, '/package/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/package/${id}/edit`)
    },
    underwriter: {
        root: path(ROOTS_DASHBOARD, '/underwriter'),
        new: path(ROOTS_DASHBOARD, '/underwriter/new'),
        list: path(ROOTS_DASHBOARD, '/underwriter/list'),
        profile: path(ROOTS_DASHBOARD, '/underwriter/profile'),
        account: path(ROOTS_DASHBOARD, '/underwriter/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/underwriter/${id}/edit`)
    },
    carrier: {
        root: path(ROOTS_DASHBOARD, '/carrier'),
        new: path(ROOTS_DASHBOARD, '/carrier/new'),
        list: path(ROOTS_DASHBOARD, '/carrier/list'),
        profile: path(ROOTS_DASHBOARD, '/carrier/profile'),
        account: path(ROOTS_DASHBOARD, '/carrier/account'),
        edit: (id) => path(ROOTS_DASHBOARD, `/carrier/${id}/edit`)
    },
    fleet: {
        root: path(ROOTS_DASHBOARD, '/vehicle'),
        new: path(ROOTS_DASHBOARD, '/vehicle/new'),
        list: path(ROOTS_DASHBOARD, '/vehicle/list'),
        edit: path(ROOTS_DASHBOARD, `/vehicle/edit`)
    },
    part: {
        root: path(ROOTS_DASHBOARD, '/part/list'),
        new: path(ROOTS_DASHBOARD, '/part/new'),
        list: path(ROOTS_DASHBOARD, '/part/list'),
        view: path(ROOTS_DASHBOARD, `/part/view`),
        edit: path(ROOTS_DASHBOARD, `/part/edit`)
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, '/e-commerce'),
        shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
        list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
        checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
        new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
        edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
        demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    },
    invoice: {
        root: path(ROOTS_DASHBOARD, '/invoice'),
        list: path(ROOTS_DASHBOARD, '/invoice/list'),
        new: path(ROOTS_DASHBOARD, '/invoice/new'),
        view: path(ROOTS_DASHBOARD, `/invoice/view`),
        edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    coverage: {
        root: path(ROOTS_DASHBOARD, '/coverage'),
        view: path(ROOTS_DASHBOARD, '/coverage/details'),
    },
    policy: {
        root: path(ROOTS_DASHBOARD, '/policy'),
        list: path(ROOTS_DASHBOARD, '/policy/list'),
        new: path(ROOTS_DASHBOARD, '/policy/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/policy/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/policy/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/policy/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/policy/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    license: {
        root: path(ROOTS_DASHBOARD, '/license'),
        list: path(ROOTS_DASHBOARD, '/license/list'),
        new: path(ROOTS_DASHBOARD, '/license/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/license/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/license/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/license/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/license/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    document: {
        root: path(ROOTS_DASHBOARD, '/document'),
        list: path(ROOTS_DASHBOARD, '/document/list'),
        new: path(ROOTS_DASHBOARD, '/document/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/document/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/document/${id}/edit`),
    },
    rate: {
        root: path(ROOTS_DASHBOARD, '/rate'),
        list: path(ROOTS_DASHBOARD, '/rate/list'),
        new: path(ROOTS_DASHBOARD, '/rate/new'),
        view: path(ROOTS_DASHBOARD, `/rate/view`),
        edit: path(ROOTS_DASHBOARD, `/rate/edit`),
    },
    dispatch: {
        root: path(ROOTS_DASHBOARD, '/dispatch'),
    },
    cost: {
        root: path(ROOTS_DASHBOARD, '/cost'),
        list: path(ROOTS_DASHBOARD, '/cost/list'),
        new: path(ROOTS_DASHBOARD, '/cost/new'),
        view: path(ROOTS_DASHBOARD, `/cost/view`),
        edit: path(ROOTS_DASHBOARD, `/cost/edit`),
    },
    blog: {
        root: path(ROOTS_DASHBOARD, '/blog'),
        posts: path(ROOTS_DASHBOARD, '/blog/posts'),
        new: path(ROOTS_DASHBOARD, '/blog/new'),
        view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
        demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    },
    role: {
        root: path(ROOTS_DASHBOARD, '/role'),
        list: path(ROOTS_DASHBOARD, '/role/list'),
        new: path(ROOTS_DASHBOARD, '/role/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/role/${id}/edit`),
    },
    service: {
        root: path(ROOTS_DASHBOARD, '/service'),
        list: path(ROOTS_DASHBOARD, '/service/list'),
        new: path(ROOTS_DASHBOARD, '/service/new'),
        edit: path(ROOTS_DASHBOARD, `/service/edit`),
    },
    vehicle: {
        root: path(ROOTS_DASHBOARD, '/vehicle'),
        list: path(ROOTS_DASHBOARD, '/vehicle/list'),
        new: path(ROOTS_DASHBOARD, '/vehicle/new'),
        edit: path(ROOTS_DASHBOARD, `/vehicle/edit`),
    },
    hoo: {
        root: path(ROOTS_DASHBOARD, '/hours-of-operation'),
        list: path(ROOTS_DASHBOARD, '/hours-of-operation/list'),
        new: path(ROOTS_DASHBOARD, '/hours-of-operation/new'),
        edit: path(ROOTS_DASHBOARD, `/hours-of-operation/edit`),
    },
    opportunity: {
        root: path(ROOTS_DASHBOARD, '/opportunity'),
        dashboard: path(ROOTS_DASHBOARD, '/opportunity/dashboard'),
        list: path(ROOTS_DASHBOARD, '/opportunity/list'),
        new: path(ROOTS_DASHBOARD, '/opportunity/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/opportunity/${id}/edit`),
    },
    networkBid: {
        root: path(ROOTS_DASHBOARD, '/network/bid'),
        list: path(ROOTS_DASHBOARD, '/network/bid/list'),
        new: path(ROOTS_DASHBOARD, '/network/bid/list'),
        view: (id) => path(ROOTS_DASHBOARD, `/network/bid/${id}`),
    },
    location: {
        root: path(ROOTS_DASHBOARD, '/location'),
        list: path(ROOTS_DASHBOARD, '/location/list'),
        new: path(ROOTS_DASHBOARD, '/location/new'),
        edit: path(ROOTS_DASHBOARD, `/location/edit`),
    },
    request: {
        root: path(ROOTS_DASHBOARD, '/request'),
        list: path(ROOTS_DASHBOARD, '/request/list'),
        new: path(ROOTS_DASHBOARD, '/request/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/request/${id}/edit`),
        view: (id) => path(ROOTS_DASHBOARD, `/request/${id}`),
    },
    support: {
        root: path(ROOTS_DASHBOARD, '/support'),
        list: path(ROOTS_DASHBOARD, '/support/list'),
        new: path(ROOTS_DASHBOARD, '/support/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/support/${id}/edit`),
        view: (id) => path(ROOTS_DASHBOARD, `/support/${id}`),
    },
    integration: {
        root: path(ROOTS_DASHBOARD, '/integration'),
        list: path(ROOTS_DASHBOARD, '/integration/list'),
        new: path(ROOTS_DASHBOARD, '/integration/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/integration/details/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/integration/${id}/edit`),
    }
};


export const PATH_CUSTOMER_PERMISSIONS = {
    [PATH_CUSTOMER.root]: '',

    [PATH_CUSTOMER.general.app]: '',
    [PATH_CUSTOMER.general.general]: '',
    [PATH_CUSTOMER.general.analytics]: '',
    [PATH_CUSTOMER.general.booking]: '',
    [PATH_CUSTOMER.general.ecommerce]: '',
    [PATH_CUSTOMER.general.banking]: '',

    [PATH_CUSTOMER.customerClaims.root]: '',
    [PATH_CUSTOMER.customerClaims.new]: '',
    [PATH_CUSTOMER.customerClaims.list]: '',
    [PATH_CUSTOMER.customerClaims.view]: '',
    [PATH_CUSTOMER.customerClaims.edit]: '',

    [PATH_CUSTOMER.customerPolicy.root]: '',
    [PATH_CUSTOMER.customerPolicy.new]: '',
    [PATH_CUSTOMER.customerPolicy.list]: '',
    [PATH_CUSTOMER.customerPolicy.view]: '',
    [PATH_CUSTOMER.customerPolicy.edit]: '',

    [PATH_CUSTOMER.location.list]: '',
    [PATH_CUSTOMER.location.edit]: '',
    [PATH_CUSTOMER.location.root]: '',
    [PATH_CUSTOMER.location.view]: '',
    [PATH_CUSTOMER.location.new]: '',

    [PATH_CUSTOMER.network.list]: '',
    [PATH_CUSTOMER.network.edit]: '',
    [PATH_CUSTOMER.network.view]: '',
    [PATH_CUSTOMER.network.root]: '',
    [PATH_CUSTOMER.network.new]: '',

    [PATH_CUSTOMER.provider.profile]: '',
    [PATH_CUSTOMER.provider.profileBid]: '',

    [PATH_CUSTOMER.hoo.list]: '',
    [PATH_CUSTOMER.hoo.edit]: '',
    [PATH_CUSTOMER.hoo.view]: '',
    [PATH_CUSTOMER.hoo.root]: '',
    [PATH_CUSTOMER.hoo.new]: '',

    [PATH_CUSTOMER.invoice.list]: '',
    [PATH_CUSTOMER.invoice.edit]: '',
    [PATH_CUSTOMER.invoice.root]: '',
    [PATH_CUSTOMER.invoice.new]: '',
    [PATH_CUSTOMER.invoice.view]: '',

    [PATH_CUSTOMER.coverage.root]: '',
    [PATH_CUSTOMER.coverage.view]: '',

    [PATH_CUSTOMER.document.list]: '',

    [PATH_CUSTOMER.networkBid.list]: ''
}


export const PATH_REGISTRATION = {
    root: ROOTS_REGISTRATION,
    organization: {
        root: path(ROOTS_REGISTRATION, '/organization'),
    },
};

export const PATH_DASHBOARD_ROLES_ACCESS = (roleLabels) => {
    // const admin0Auth = [roleLabels.god]
    // const admin1Auth = [roleLabels.doloomaSuperUser];
    // const admin2Auth = [roleLabels.superUser, roleLabels.doloomaSupport];
    // const admin3Auth = [roleLabels.superUser];
    /*
        god: 'God',
            doloomaSuperUser: 'Dolooma Super User',
            doloomaSupport: 'Dolooma Support',
            superUser: 'Super User',
            superAdmin: 'Super Admin',
            support: 'Support',
            claimsAdmin: 'Claims Admin',
            claimsSupport: 'Claims Support',
            claimsManager: 'Claims Manager',
            policyManager: 'Policy Manager',
            adjuster: 'Adjuster',
            underwriter: 'Underwriter',
            lender: 'Lender',
            lenderManager: 'Lender Manager',
            agent: 'Agent',
            policy: 'Policy',
            agentManager: 'Agent Manager',
            broker: 'Broker',
            finance: 'Finance',
            financeManager: 'Finance Manager',
            /*
     */

    return {
        [PATH_DASHBOARD.root]: [roleLabels.god, roleLabels.superUser, roleLabels.doloomaSuperUser],
        [PATH_DASHBOARD.general.app]: [roleLabels.god],
        [PATH_DASHBOARD.general.ecommerce]: [roleLabels.god],
        [PATH_DASHBOARD.general.analytics]: [roleLabels.god],
        [PATH_DASHBOARD.general.banking]: [roleLabels.god],
        [PATH_DASHBOARD.general.booking]: [roleLabels.god],

        [PATH_DASHBOARD.mail.root]: [roleLabels.god],
        [PATH_DASHBOARD.mail.all]: [roleLabels.god],

        [PATH_DASHBOARD.chat.root]: [roleLabels.god],
        [PATH_DASHBOARD.chat.new]: [roleLabels.god],
        [PATH_DASHBOARD.chat.view]: [roleLabels.god],

        [PATH_DASHBOARD.calendar]: [roleLabels.god],
        [PATH_DASHBOARD.claims]: [roleLabels.god],

        [PATH_DASHBOARD.user.root]: [roleLabels.god],
        [PATH_DASHBOARD.user.new]: [roleLabels.god],
        [PATH_DASHBOARD.user.list]: [roleLabels.god],
        [PATH_DASHBOARD.user.cards]: [roleLabels.god],
        [PATH_DASHBOARD.user.profile]: [roleLabels.god],
        [PATH_DASHBOARD.user.account]: [roleLabels.god],
        [PATH_DASHBOARD.user.edit]: [roleLabels.god],
        [PATH_DASHBOARD.user.demoEdit]: [roleLabels.god],

        [PATH_DASHBOARD.user.root]: [roleLabels.god],
        [PATH_DASHBOARD.user.new]: [roleLabels.god],
        [PATH_DASHBOARD.user.list]: [roleLabels.god],
        [PATH_DASHBOARD.user.profile]: [roleLabels.god],
        [PATH_DASHBOARD.user.account]: [roleLabels.god],
        [PATH_DASHBOARD.user.edit]: [roleLabels.god],

        [PATH_DASHBOARD.carrier.root]: [roleLabels.god],
        [PATH_DASHBOARD.carrier.new]: [roleLabels.god],
        [PATH_DASHBOARD.carrier.list]: [roleLabels.god],
        [PATH_DASHBOARD.carrier.profile]: [roleLabels.god],
        [PATH_DASHBOARD.carrier.account]: [roleLabels.god],
        [PATH_DASHBOARD.carrier.edit]: [roleLabels.god],

        [PATH_DASHBOARD.eCommerce.root]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.shop]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.list]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.checkout]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.new]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.view]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.edit]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.demoEdit]: [roleLabels.god],
        [PATH_DASHBOARD.eCommerce.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.invoice.root]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.list]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.new]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.view]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.edit]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.demoEdit]: [roleLabels.god],
        [PATH_DASHBOARD.invoice.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.policy.root]: [roleLabels.god],
        [PATH_DASHBOARD.policy.list]: [roleLabels.god],
        [PATH_DASHBOARD.policy.new]: [roleLabels.god],
        [PATH_DASHBOARD.policy.view]: [roleLabels.god],
        [PATH_DASHBOARD.policy.edit]: [roleLabels.god],
        [PATH_DASHBOARD.policy.demoEdit]: [roleLabels.god],
        [PATH_DASHBOARD.policy.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.license.root]: [roleLabels.god],
        [PATH_DASHBOARD.license.list]: [roleLabels.god],
        [PATH_DASHBOARD.license.new]: [roleLabels.god],
        [PATH_DASHBOARD.license.view]: [roleLabels.god],
        [PATH_DASHBOARD.license.edit]: [roleLabels.god],
        [PATH_DASHBOARD.license.demoEdit]: [roleLabels.god],
        [PATH_DASHBOARD.license.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.rate.root]: [roleLabels.god],
        [PATH_DASHBOARD.rate.list]: [roleLabels.god],
        [PATH_DASHBOARD.rate.new]: [roleLabels.god],
        [PATH_DASHBOARD.rate.view]: [roleLabels.god],
        [PATH_DASHBOARD.rate.edit]: [roleLabels.god],
        [PATH_DASHBOARD.rate.demoEdit]: [roleLabels.god],
        [PATH_DASHBOARD.rate.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.blog.root]: [roleLabels.god],
        [PATH_DASHBOARD.blog.posts]: [roleLabels.god],
        [PATH_DASHBOARD.blog.new]: [roleLabels.god],
        [PATH_DASHBOARD.blog.view]: [roleLabels.god],
        [PATH_DASHBOARD.blog.demoView]: [roleLabels.god],

        [PATH_DASHBOARD.role.root]: [roleLabels.god],
        [PATH_DASHBOARD.role.list]: [roleLabels.god],
        [PATH_DASHBOARD.role.new]: [roleLabels.god],
        [PATH_DASHBOARD.role.edit]: [roleLabels.god],

        [PATH_DASHBOARD.service.root]: [roleLabels.god],
        [PATH_DASHBOARD.service.list]: [roleLabels.god],
        [PATH_DASHBOARD.service.new]: [roleLabels.god],
        [PATH_DASHBOARD.service.edit]: [roleLabels.god],

        [PATH_DASHBOARD.hoo.root]: [roleLabels.god],
        [PATH_DASHBOARD.hoo.list]: [roleLabels.god],
        [PATH_DASHBOARD.hoo.new]: [roleLabels.god],
        [PATH_DASHBOARD.hoo.edit]: [roleLabels.god],

        [PATH_DASHBOARD.location.root]: [roleLabels.god],
        [PATH_DASHBOARD.location.list]: [roleLabels.god],
        [PATH_DASHBOARD.location.new]: [roleLabels.god],
        [PATH_DASHBOARD.location.edit]: [roleLabels.god],

        [PATH_DASHBOARD.appAsset.root]: [roleLabels.god],
        [PATH_DASHBOARD.appAsset.list]: [roleLabels.god],
        [PATH_DASHBOARD.appAsset.new]: [roleLabels.god],
        [PATH_DASHBOARD.appAsset.edit]: [roleLabels.god],

        [PATH_DASHBOARD.company.root]: [roleLabels.god],
        [PATH_DASHBOARD.company.list]: [roleLabels.god],
        [PATH_DASHBOARD.company.new]: [roleLabels.god],
        [PATH_DASHBOARD.company.edit]: [roleLabels.god],

        [PATH_DASHBOARD.crm.root]: [roleLabels.god],
        [PATH_DASHBOARD.crm.list]: [roleLabels.god],
        [PATH_DASHBOARD.crm.new]: [roleLabels.god],
        [PATH_DASHBOARD.crm.edit]: [roleLabels.god],

        [PATH_DASHBOARD.package.root]: [roleLabels.god],
        [PATH_DASHBOARD.package.list]: [roleLabels.god],
        [PATH_DASHBOARD.package.new]: [roleLabels.god],
        [PATH_DASHBOARD.package.edit]: [roleLabels.god]
    };
}
