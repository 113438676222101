import {createContext, useEffect, useReducer} from 'react';
import {Auth0Client} from '@auth0/auth0-spa-js';
import PropTypes from 'prop-types';
import {AUTH0_API} from '../config';
import axios from '../utils/axios';
import {setSession} from '../utils/jwt';

let auth0Client = null;

const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    organization: null,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user, organization} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            organization,
            user,
        };
    },
    LOGIN: (state, action) => {
        const {user, organization} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            organization,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        organization: null,
        user: null,
    }),
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'Auth0',
    recollectAccountInfo: () => Promise.resolve(),
    logInWithPopUp: () => Promise.resolve(),
    refreshUser: () => Promise.resolve(),
    loginWithRedirect: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

const AuthProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const buildUserObject = (user, doloomaUser) => {
        if (doloomaUser?._id && !doloomaUser?.emailVerified) {
            axios.post(`/api/users`, {
                users: [{
                    _id: doloomaUser._id,
                    status: 'active',
                    emailVerified: true,
                }],
            });
        }
        return {
            userId: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            emailVerified: user.email_verified,
            firstName: user.given_name,
            lastName: user.family_name,
            role: user.role,
            location: user.location,
            username: user.username,
            nickname: user.nickname,
            doloomaPermissionsConnections: user['dolooma-permissions/connections'],
            doloomaPermissionsRoles: user['dolooma-permissions/roles'],
            ...doloomaUser,
        };
    };

    //
    // const redirectAtLogin = (user, organization, redirect) => {
    //     const role = find(user.permissions, {id: organization._id})
    //     if (role?.userType !== 'Business' && redirect.includes('/dashboard/general')) {
    //         console.log('Auth0 >>redirectAtLogin >> customer', CUSTOMER_PATH_AFTER_LOGIN)
    //         return CUSTOMER_PATH_AFTER_LOGIN
    //     }
    //     console.log('Auth0 >>redirectAtLogin >> business', redirect)
    //     return redirect;
    // }

    useEffect(() => {
        const initialize = async () => {
            try {
                auth0Client = new Auth0Client({
                    redirect_uri: process.env.REACT_APP_APP_URL,
                    cacheLocation: 'localstorage',
                    ...AUTH0_API,
                });

                const pathRedirect = window.localStorage.getItem('loginUrl');

                const query = window.location.search;
                if (query.includes('code=') && query.includes('state=')) {
                    try {
                        await auth0Client.handleRedirectCallback();
                    } catch (e) {
                        console.error('handleRedirectCallback error', e);
                    }
                    if (pathRedirect) {
                        window.location.href = window.location.origin + pathRedirect;
                    } else {
                        window.history.replaceState({}, document.title, '/');
                    }
                } else {
                    await auth0Client.checkSession();
                }

                const isAuthenticated = await auth0Client.isAuthenticated();

                if (isAuthenticated) {
                    const user = await auth0Client.getUser();
                    const accessToken = await auth0Client.getTokenSilently();
                    const idToken = await auth0Client.getIdTokenClaims();

                    setSession(accessToken, idToken.__raw);

                    const accountResponse = await axios.get(`/api/organizations`, {
                        params: {
                            userId: user.sub,
                        },
                    });

                    const {
                        data: {organizations = [], users = [], auth0Users = []},
                    } = accountResponse;
                    const organization = organizations[0] || null;
                    const doloomaUser = users[0] || null;
                    const auth0User = auth0Users[0] || null;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: buildUserObject({...user, ...auth0User}, doloomaUser),
                            organization,
                        },
                    });

                    // if (['dashboard', 'registration', 'customer'].some((authRoot) => window.location.pathname.includes(authRoot))) {
                    //     if (pathRedirect && organization && window.location.pathname !== pathRedirect) {
                    //         window.localStorage.removeItem('loginUrl');
                    //         window.location.pathname = redirectAtLogin(buildUserObject({...user, ...auth0User}, doloomaUser), organization, pathRedirect);
                    //     } else if (!organization && window.location.pathname !== PATH_REGISTRATION.organization.root) {
                    //         window.location.pathname = PATH_REGISTRATION.organization.root;
                    //     }
                    // } else if (pathRedirect && organization && window.location.pathname !== pathRedirect) {
                    //     window.localStorage.removeItem('loginUrl');
                    //     window.location.pathname = redirectAtLogin(buildUserObject({...user, ...auth0User}, doloomaUser), organization, pathRedirect);
                    // }
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: null,
                        },
                    });
                    if (pathRedirect === '/dashboard') {
                        loginWithRedirect(pathRedirect)
                    }
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        organization: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const loginWithRedirect = async () => {

        window.location.href = `${process.env.REACT_APP_APP_URL}/auth/login/yes`

    };

    const loginWithPopup = async (options) => {
        await auth0Client.loginWithPopup(options);

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
            const user = await auth0Client.getUser();

            const accountResponse = await axios.get(`/api/organizations`, {
                params: {
                    userId: user.sub,
                },
            });

            const {
                data: {organizations = [], users = []},
            } = accountResponse;
            const organization = organizations[0] || null;
            const doloomaUser = users[0] || null;

            dispatch({
                type: 'LOGIN',
                payload: {
                    user: buildUserObject(user, doloomaUser),
                    organization,
                },
            });
        }
    };

    const refreshUser = async () => {

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {

            const accessToken = await auth0Client.getTokenSilently();

            window.localStorage.setItem('accessToken', accessToken);

            const idToken = await auth0Client.getIdTokenClaims();

            const user = await auth0Client.getUser();

            axios.defaults.headers.common['x-dolooma-authentication'] = accessToken;
            axios.defaults.headers.common['x-dolooma-authorization'] = idToken.__raw;

            let organization = null;
            let doloomaUser = null;
            let auth0User = null;

            try {
                const accountResponse = await axios.get(`/api/organizations`, {
                    params: {
                        userId: user.sub,
                    },
                });

                const {
                    data: {organizations = [], users = [], auth0Users = []},
                } = accountResponse;
                organization = organizations[0] || null;
                doloomaUser = users[0] || null;
                auth0User = auth0Users[0] || null;

            } catch (e) {
                console.error(e);
            }

            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: buildUserObject({...user, ...auth0User}, doloomaUser),
                    organization,
                },
            });

        } else {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: null,
                    organization: null,
                },
            });
        }
    };

    const recollectAccountInfo = async () => refreshUser();

    const logout = async () => {
        window.localStorage.removeItem('accessToken');
        await auth0Client.logout();
        dispatch({
            type: 'LOGOUT',
        });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'Auth0',
                recollectAccountInfo,
                refreshUser,
                loginWithPopup,
                loginWithRedirect,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {AuthContext, AuthProvider};
