import PropTypes from 'prop-types';
// @mui
import {alpha} from '@mui/material/styles';
import {Button, IconButton, Link, Stack, Tooltip} from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

SocialsButton.propTypes = {
    initialColor: PropTypes.bool,
    links: PropTypes.objectOf(PropTypes.string),
    simple: PropTypes.bool,
    company: PropTypes.string,
    sx: PropTypes.object,
};

export default function SocialsButton(
    {
        company = '',
        initialColor = false,
        simple = true,
        links = {
            facebook: 'https://www.facebook.com/profile.php?id=100087481974145',
            youtube: 'https://www.youtube.com/channel/UCiAU6-xtCzykbZU5XVQxCFA',
            linkedin: 'https://www.linkedin.com/company/dolooma/about/',
            twitter: 'https://twitter.com/dolooma1',
            chacha: {
                facebook: 'https://www.facebook.com',
                twitter: 'https://twitter.com',
                linkedin: 'https://www.linkedin.com',
            }
        },
        hideMissingLinks = true,
        sx,
        ...other
    }
) {

    const buildLink = (company, linkName) => {
        let link = ''
        if (company) {
            link = company && links[company] ? links[company][linkName] : null;
        } else {
            link = links[linkName];
        }

        if (!link && hideMissingLinks) {
            return null;
        }

        return link;

    }

    const SOCIALS = [
        {
            id: 'facebook',
            name: 'FaceBook',
            icon: 'eva:facebook-fill',
            socialColor: '#1877F2',
            path: buildLink(company, 'facebook')
        },
        {
            id: 'youtube',
            name: 'YouTube',
            icon: 'uil:youtube',
            socialColor: '#E02D69',
            path: buildLink(company, 'youtube')
        },
        {
            id: 'linkedin',
            name: 'Linkedin',
            icon: 'eva:linkedin-fill',
            socialColor: '#007EBB',
            path: buildLink(company, 'linkedin')
        },
        {
            id: 'twitter',
            name: 'Twitter',
            icon: 'eva:twitter-fill',
            socialColor: '#00AAEC',
            path: buildLink(company, 'twitter')
        },
        {
            id: 'website',
            name: 'Website',
            icon: 'icon-park-outline:web-page',
            socialColor: 'rgba(13, 160, 189)',
            path: buildLink(company, 'website')
        },
        {
            id: 'bookings',
            name: 'Calendar',
            icon: 'solar:calendar-linear',
            socialColor: 'rgba(104, 13, 189)',
            path: buildLink(company, 'bookings')
        },
    ];


    return (
        <Stack direction="row" flexWrap="wrap" alignItems="center">
            {SOCIALS.filter((link) => !hideMissingLinks || (hideMissingLinks && links[link.id])).map((social) => {
                const {name, icon, path, socialColor} = social;
                if (!path) {
                    return null;
                }
                return simple ? (
                    <Link key={name} href={path} target={"_blank"}>
                        <Tooltip title={name} placement="top">
                            <IconButton
                                color="inherit"
                                sx={{
                                    ...(initialColor && {
                                        color: socialColor,
                                        '&:hover': {
                                            bgcolor: alpha(socialColor, 0.08),
                                        },
                                    }),
                                    ...sx,
                                }}
                                {...other}
                            >
                                <Iconify icon={icon} sx={{width: 20, height: 20}}/>
                            </IconButton>
                        </Tooltip>
                    </Link>
                ) : (
                    <Button
                        key={name}
                        href={path}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        startIcon={<Iconify icon={icon}/>}
                        sx={{
                            m: 0.5,
                            flexShrink: 0,
                            ...(initialColor && {
                                color: socialColor,
                                borderColor: socialColor,
                                '&:hover': {
                                    borderColor: socialColor,
                                    bgcolor: alpha(socialColor, 0.08),
                                },
                            }),
                            ...sx,
                        }}
                        {...other}
                    >
                        {name}
                    </Button>
                );
            })}
        </Stack>
    );
}
