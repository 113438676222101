import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    parts: [],
    partModifiers: [],
    partVehicleSizes: [],
    partsForConsumer: [],
    partQuestions: [],
    partSymptoms: [],
    partProblemCodes: [],
    part: null
};

const slice = createSlice({
    name: 'part',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getPartsSuccess(state, action) {
            state.isLoading = false;
            state.parts = action.payload;
        },

        // GET SERVICE QUESTIONS
        getPartQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.partQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getPartSymptomsSuccess(state, action) {
            state.isLoading = false;
            state.partSymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getPartModifiersSuccess(state, action) {
            state.isLoading = false;
            state.partModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getPartVehicleSizesSuccess(state, action) {
            state.isLoading = false;
            state.partVehicleSizes = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getPartProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.partProblemCodes = action.payload;
        },

        // GET SERVICE
        getPartSuccess(state, action) {
            state.isLoading = false;
            state.part = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getParts(forConsumer, callback) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/parts', {params: {forConsumer}});
            dispatch(slice.actions.getPartsSuccess(response.data.parts));
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/questions');
            dispatch(slice.actions.getPartQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/problem/codes');
            dispatch(slice.actions.getPartProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/symptoms');
            dispatch(slice.actions.getPartSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/modifiers');
            console.log('getPartModifiers', {response})
            dispatch(slice.actions.getPartModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartVehicleSizes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/vehicle/sizes');
            console.log('getPartVehicleSizes', {response})
            dispatch(slice.actions.getPartVehicleSizesSuccess(response.data.vehicleSizes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPart(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/part`, {
                params: {identifier},
            });
            dispatch(slice.actions.getPartSuccess(response?.data?.part ? response.data.part[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setPart(part) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getPartSuccess(part));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
