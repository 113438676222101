import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {filter} from "lodash";

// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}

const initialState = {
    isLoading: false,
    error: null,
    contacts: {byId: {}, allIds: []},
    conversations: {byId: {}, allIds: []},
    activeConversationId: null,
    participants: [],
    recipients: [],
    messages: [],
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CONTACT SSUCCESS
        getContactsSuccess(state, action) {
            const contacts = action.payload;

            state.contacts.byId = objFromArray(contacts);
            state.contacts.allIds = Object.keys(state.contacts.byId);
        },

        // GET CONVERSATIONS
        getConversationsSuccess(state, action) {
            const conversations = action.payload;
            state.isLoading = false

            state.conversations.byId = objFromArray(conversations);
            state.conversations.allIds = Object.keys(state.conversations.byId);
        },
        // GET CONVERSATIONS
        getConversationsSuccessTwo(state, action) {
            const {_id, data: {type, ...rest}} = action.payload;
            state.isLoading = false

            state.conversations.allIds.forEach((id) => {
                state.conversations.byId[id].participants.forEach((p, pi) => {
                    if (p._id === _id) {

                        state.conversations.byId[id].participants[pi] = {
                            ...state.conversations.byId[id].participants[pi], ...rest
                        }
                    }
                })
            })

        },

        // GET CONVERSATION
        getConversationSuccess(state, action) {
            const conversation = action.payload[0];
            state.isLoading = false;

            if (conversation) {
                const {participants, recipients} = conversation
                if (participants) {
                    state.participants = participants
                }
                if (recipients) {
                    state.recipients = recipients
                }
                if (state.conversations.byId[conversation._id]) {
                    state.conversations.byId[conversation._id] = {
                        ...state.conversations.byId[conversation._id],
                        ...conversation
                    };
                } else {
                    state.conversations.byId[conversation._id] = conversation
                }
                state.activeConversationId = conversation._id;
                if (!state.conversations.allIds.includes(conversation._id)) {
                    state.conversations.allIds.push(conversation._id);
                }
            } else {
                state.activeConversationId = null;
            }
        },

        // ON RECEIVE MESSAGES
        onReceiveMessages(state, action) {
            const messages = action.payload;
            messages.forEach((m) => {
                const {conversationId} = m;
                const existingIndex = state.conversations.byId[m.conversationId].messages.findIndex((m2) => m2.messageId === m.messageId);
                if (existingIndex >= 0) {
                    state.conversations.byId[conversationId].messages[existingIndex] = m;
                } else {
                    state.conversations.byId[conversationId].messages.push(m);
                }
            })
        },

        // ON SEND MESSAGE
        onSendMessage(state, action) {
            const {message, conversationId} = action.payload;
            const existingIndex = state.conversations.byId[conversationId].messages.findIndex((m) => m.messageId === message.messageId);
            if (existingIndex >= 0) {
                state.conversations.byId[conversationId].messages[existingIndex] = message;
            } else {
                state.conversations.byId[conversationId].messages.push(message);
            }
        },

        // ON SEND MESSAGE
        onInitialMessage(state, action) {
            const {message, conversationId} = action.payload;
            state.conversations.byId[conversationId] = {_id: conversationId, messages: [message]}
            state.conversations.allIds = Object.keys(state.conversations.byId);
        },

        markConversationAsReadSuccess(state, action) {
            const {participants, recipients, conversationId, unreadList, messages} = action.payload;
            if (participants) {
                state.participants = participants;
            }
            if (recipients) {
                state.participants = recipients;
            }
            if (messages) {
                state.conversations.byId[conversationId].messages = messages;
            }
            state.conversations.byId[conversationId].unreadList = unreadList
        },

        // GET PARTICIPANTS
        getParticipantsSuccess(state, action) {
            const participants = action.payload;
            state.participants = participants;
        },

        // MARK UNREAD
        setConversationUnRead(state, action) {
            try {
                const {unreadList, conversationId} = action.payload;
                state.conversations.byId[conversationId].unreadList = unreadList;
            } catch (e) {
                console.error(e)
            }
        },

        // GET RECIPIENTS
        getRecipientsSuccess(state, action) {
            const participants = action.payload;
            state.participants = participants;
        },

        // RESET ACTIVE CONVERSATION
        resetActiveConversation(state) {
            state.activeConversationId = null;
            state.participants = [];
            state.recipients = [];
        },

        addRecipients(state, action) {
            const {recipients} = action.payload;
            state.recipients = recipients;
            state.isLoading = false;
        },

        removeChat(state, action) {
            const conversationId = action.payload;
            delete state.conversations.byId[conversationId];
            state.conversations.allIds = Object.keys(state.conversations.byId);
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {onSendMessage, resetActiveConversation, getRecipientsSuccess, getParticipantsSuccess} = slice.actions;

// ----------------------------------------------------------------------

export function addRecipients({recipients, conversationId}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            if (conversationId) {
                const response = await axios.post('/api/chat/recipients', {
                    conversationId,
                    recipients
                });
                dispatch(slice.actions.addRecipients(response.data));
            } else {
                dispatch(slice.actions.addRecipients({recipients}));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function leaveChat({conversationId, userId}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            if (conversationId) {
                await axios.post('/api/chat', {
                    conversation: {_id: conversationId, userId, leaveChat: true}
                });
                dispatch(slice.actions.removeChat(conversationId));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getContacts(stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/chat/contacts');
            dispatch(slice.actions.getContactsSuccess(response.data.contacts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getConversations(userId, stealth) {
    return async () => {
        if (!stealth) {
            dispatch(slice.actions.startLoading());
        }
        try {
            const response = await axios.get('/api/chat', {params: {userId}});

            dispatch(slice.actions.getConversationsSuccess(response.data.conversations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setConversations(conversations, stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getConversationSuccess(conversations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey, userId, stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get('/api/chat', {
                params: {conversationKey, userId},
            });

            dispatch(slice.actions.getConversationSuccess(response.data.conversations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createConversation(data, stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());
        try {
            const {participants, recipients, message, ...conversation} = data;

            const conversationResponse = await axios.put('/api/chat', {
                conversation,
                participants,
                recipients
            });

            const {
                _id: conversationId,
                createdDate,
                modifiedDate,
                unreadList
            } = conversationResponse.data.conversation[0];

            dispatch(slice.actions.getConversationSuccess([{
                _id: conversationId,
                createdDate,
                participants: [],
                recipients: [],
                modifiedDate,
                unreadList,
                messages: [{...message, conversationId}]
            }]));

            const participantsResponse = await axios.put('/api/chat/participants', {
                participants: participants.map((p) => ({...p, conversationId})),
            });

            const recipientsResponse = await axios.put('/api/chat/recipients', {
                recipients: recipients.map((r) => ({...r, conversationId}))
            });

            const messagesResponse = await axios.put('/api/chat/conversations', {
                message: {...message, conversationId}
            });

            dispatch(slice.actions.getConversationSuccess([{
                ...conversationResponse.data.conversation[0],
                participants: participantsResponse.data.participants,
                recipients: recipientsResponse.data.recipients,
                messages: messagesResponse.data.messages,
            }]));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function onReceiveMessages(messages) {
    return async () => {
        try {
            dispatch(slice.actions.onReceiveMessages(messages));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function sendMessage(message) {
    return async () => {
        try {
            const {conversationId, unreadList, ...rest} = message
            dispatch(slice.actions.setConversationUnRead({unreadList, conversationId}))
            dispatch(slice.actions.onSendMessage({message: {...rest, unreadList}, conversationId}));
            const response = await axios.post('/api/chat/conversations', {
                message: {
                    conversationId,
                    unreadList,
                    ...rest
                }
            });
            dispatch(slice.actions.onSendMessage(response.data.conversation[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId, _id, unreadList) {
    return async () => {
        try {

            const newUnreadList = filter(unreadList, (item) => item !== _id);

            dispatch(slice.actions.markConversationAsReadSuccess({
                conversationId,
                unreadList: newUnreadList
            }));

            const chatResponse = await axios.post('/api/chat', {
                conversation: {
                    _id: conversationId,
                    unreadList: newUnreadList
                }
            });

            const conversationResponse = await axios.post('/api/chat/conversations', {
                message: {conversationId},
                userId: _id,
                markAsRead: true
            });

            dispatch(slice.actions.markConversationAsReadSuccess({
                conversationId,
                unreadList: chatResponse.data.conversation[0].unreadList,
                messages: conversationResponse.data.messages
            }));

        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey, stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/chat/participants', {
                params: {_id: conversationKey},
            });

            dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setParticipantStatus(_id, data) {
    return async () => {
        try {
            dispatch(slice.actions.getConversationsSuccessTwo({_id, data}))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getRecipients(conversationKey, stealth) {
    return async () => {
        !stealth && dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/chat/recipients', {
                params: {_id: conversationKey},
            });
            dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
