import { createSlice } from '@reduxjs/toolkit';
// utils
import { find } from 'lodash';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  organizationCollected: false,
  organizationsCollected: false,
  error: null,
  organizations: [],
  organization: null,
  profileComplete: 0,
  loadingProfile: false,
  profileLoaded: false,
  criticalItemsMissing: [],
  itemsMissing: [],
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startLoadingProfile(state) {
      state.loadingProfile = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATION
    getOrganizationSuccess(state, action) {
      state.isLoading = false;
      const { organizations, activeId } = action.payload;
      state.organization = organizations[0];
      const activeOrganization = activeId ? find(organizations, { _id: activeId }) : null;
      if (activeOrganization) {
        state.activeOrganization = activeOrganization;
      }
      state.organizationCollected = true;
    },

    // GET ORGANIZATION
    setOrganizationSuccess(state, action) {
      state.activeOrganization = action.payload;
    },

    // GET CHILD ORGANIZATION
    getOrganizationsSuccess(state, action) {
      state.isLoading = false;
      const { organizations, activeId } = action.payload;
      state.organizationsCollected = true;
      state.organizations = organizations;
      const activeOrganization = activeId ? find(organizations, { _id: activeId }) : null;
      if (activeOrganization) {
        state.activeOrganization = activeOrganization;
      }
    },

    // GET ORGANIZATION PROFILE
    getOrganizationProfileSuccess(state, action) {
      state.loadingProfile = false;
      state.profileLoaded = true;
      state.profileComplete = action.payload.company;
      state.profileState = action.payload.profileState;
      state.criticalItemsMissing = action.payload.criticalItemsMissing;
      state.itemsMissing = action.payload.itemsMissing;
    },

    // RESET STATE
    resetOrganizationProfile(state) {
      state.loadingProfile = false;
      state.profileComplete = 0;
      state.loadingProfile = false;
      state.profileLoaded = false;
      state.criticalItemsMissing = [];
      state.itemsMissing = [];
    },

    // SET ACTIVE ORG
    setActiveOrganizationSuccess(state, action) {
      state.activeOrganization = action.payload;
    },
  },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrganization(id, activeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/organizations/${id}`);
      const { organizations } = response.data;
      dispatch(slice.actions.getOrganizationSuccess({ organizations, activeId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setActiveOrganization(organization) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setActiveOrganizationSuccess(organization));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function setOrganization(org) {
  return async () => {
    try {
      dispatch(slice.actions.setOrganizationSuccess(org));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrganizations(parentOrganization, activeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/organizations`, {
        params: { parentOrganization },
      });
      const { organizations } = response.data;
      dispatch(slice.actions.getOrganizationsSuccess({ organizations, activeId }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrganizationProfile(_id) {
  return async () => {
    dispatch(slice.actions.startLoadingProfile());
    try {
      const response = await axios.get(`/api/organization/profile`, {
        params: { _id },
      });
      dispatch(slice.actions.getOrganizationProfileSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearOrganizationProfile() {
  return async () => {
    try {
      dispatch(slice.actions.resetOrganizationProfile());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
