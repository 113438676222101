import about from './about.json'
import blogs from './blogs.json'
import faq from './faq.json'
import home from './home.json'
import login from './login.json'
import profiles from './profiles.json'
import register from './register.json'

const translation = {...about, ...blogs, ...faq, ...home, ...login, ...profiles, ...register}
export default translation
